
import Vue from "vue";
import Hashids from "hashids";
import { mapMutations } from "vuex";


export default Vue.extend({
  name: "App",
  data: () => ({
    icons: [],
    widthOfLogo: {},
    drawer: false,
    tab: "",
    tabitems: [
      {
        name: "Meine Prüfung",
        routerlink: "/exam"
      }, {
        name: "Allgemeine Anforderungen",
        routerlink: "/"
      }, {
        name: "Weiterführende Informationen",
        routerlink: "/relatedlinks"
      }
    ],
    links: [/*{
      name: "Kontakt",
      routerlink: "/contact"
    },*/ {
      name: "Impressum",
      routerlink: "/imprint"
    }, {
      name: "Datenschutz",
      routerlink: "/privacy"
    }
    ],
    hideSlider: false,
    darkmodeswitchvalue: false,
    Hashids: "",
    HashidsHex: "",
    alert: false,
    group: null,
    drivingtasks: [
      {
        name: "Ein- und Ausfädelungsstreifen, Fahrstreifenwechsel",
        id: 1,
        img_src: require("@/assets/img/fa/1.jpg"),
        subdrivingtasks: [
          {
            name: "Einfädelungsstreifen",
            id: 1,
            img_src: require("@/assets/img/fa/11.jpg")
          },
          {
            name: "Ausfädelungsstreifen",
            id: 2,
            img_src: require("@/assets/img/fa/12.jpg")
          },
          {
            name: "Fahrstreifenwechsel",
            id: 3,
            img_src: require("@/assets/img/fa/13.jpg")
          }
        ]
      },
      {
        name: "Kurve",
        id: 2,
        img_src: require("@/assets/img/fa/2.jpg"),
        subdrivingtasks: []
      },
      {
        name: "Vorbeifahren, Überholen",
        id: 3,
        img_src: require("@/assets/img/fa/3.jpg"),
        subdrivingtasks: [
          {
            name: "Vorbeifahren an Hindernissen und Engstellen",
            id: 1,
            img_src: require("@/assets/img/fa/31.jpg")
          },
          {
            name: "Überholen anderer Verkehrsteilnehmer",
            id: 2,
            img_src: require("@/assets/img/fa/32.jpg")
          }
        ]
      },
      {
        name: "Kreuzung, Einmündung, Einfahren",
        id: 4,
        img_src: require("@/assets/img/fa/4.jpg"),
        subdrivingtasks: [

          {
            name: "Überqueren von Kreuzungen und Einmündungen",
            img_src: require("@/assets/img/fa/41.jpg"),
            id: 1,
            situationsubclasses: [{
              name: "Rechts vor Links",
              id: 1
            }, {
              name: "Mit vorfahrtregelnden Verkehrszeichen",
              id: 2
            }, {
              name: "Mit Lichtzeichenanlage",
              id: 3
            }, {
              name: "Mit Regelung durch Polizeibeamte",
              id: 4
            }
            ]
          },
          {
            name: "Rechtsabbiegen an Kreuzungen und Einmündungen",
            img_src: require("@/assets/img/fa/42.jpg"),
            id: 2,
            situationsubclasses: [{
              name: "Rechts vor Links",
              id: 1
            }, {
              name: "Mit vorfahrtregelnden Verkehrszeichen",
              id: 2
            }, {
              name: "Mit Lichtzeichenanlage",
              id: 3
            }, {
              name: "Mit Regelung durch Polizeibeamte",
              id: 4
            }
            ]
          },
          {
            name: "Linksabbiegen an Kreuzungen und Einmündungen",
            img_src: require("@/assets/img/fa/43.jpg"),
            id: 3,
            situationsubclasses: [{
              name: "Rechts vor Links",
              id: 1
            }, {
              name: "Mit vorfahrtregelnden Verkehrszeichen",
              id: 2
            }, {
              name: "Mit Lichtzeichenanlage",
              id: 3
            }, {
              name: "Mit Regelung durch Polizeibeamte",
              id: 4
            }
            ]
          },
          {
            name: "Einfahren",
            img_src: require("@/assets/img/fa/44.jpg"),
            id: 4
          }
        ]
      },
      {
        name: "Kreisverkehr",
        id: 5,
        img_src: require("@/assets/img/fa/5.jpg"),
        subdrivingtasks: []
      },
      {
        name: "Schienenverkehr",
        id: 6,
        img_src: require("@/assets/img/fa/6.jpg"),
        subdrivingtasks: [
          {
            name: "Heranfahren an und Überqueren von Bahnübergängen",
            id: 1,
            img_src: require("@/assets/img/fa/61.jpg")
          },
          {
            name: "Annäherung an Straßenbahnen und/oder Straßenbahnschienen",
            id: 2,
            img_src: require("@/assets/img/fa/6.jpg")
          }
        ]
      },
      {
        name: "Haltestelle, Fußgängerüberweg",
        id: 7,
        img_src: require("@/assets/img/fa/7.jpg"),
        subdrivingtasks: [
          {
            name: "Annähern und Vorbeifahren an Haltestellen für Busse/Straßenbahnen",
            id: 1,
            img_src: require("@/assets/img/fa/71.jpg")
          },
          {
            name: "Annähern an und Überqueren von Fußgängerüberwegen",
            id: 2,
            img_src: require("@/assets/img/fa/7.jpg")
          }
        ]
      },
      {
        name: "Geradeausfahren",
        id: 8,
        img_src: require("@/assets/img/fa/8.jpg"),
        subdrivingtasks: []
      }
    ],
    observationcats: [
      {
        name: "Verkehrs&shy;beobachtung",
        name2: "Verkehrsbeobachtung",
        img_src: require("@/assets/img/fa/1.jpg"),
        video_src: "",
        icon: "mdi-eye"
      },
      {
        name: "Fahrzeug&shy;positionierung",
        name2: "Fahrzeugpositionierung",
        img_src: require("@/assets/img/fa/2.jpg"),
        video_src: "",
        icon: "mdi-car-arrow-right"
      },
      {
        name: "Geschwindig&shy;keits&shy;anpassung",
        name2: "Geschwindigkeitsanpassung",
        img_src: require("@/assets/img/fa/3.jpg"),
        video_src: "",
        icon: "mdi-car-speed-limiter"
      },
      {
        name: "Kommunikation",
        name2: "Kommunikation",
        img_src: require("@/assets/img/fa/4.jpg"),
        video_src: "",
        icon: "mdi-account-switch"
      },
      {
        name: "Fahrzeug&shy;bedienung/ Umweltbewusste Fahrweise",
        name2: "Fahrzeugbedienung/Umweltbewusste Fahrweise",
        img_src: require("@/assets/img/fa/5.jpg"),
        video_src: "",
        icon: "mdi-car-cog"
      }
    ],
    classes: [
      "AM", "A1", "A2", "A", "B", "BE", "C1", "C1E", "C", "CE", "D1", "D1E", "D", "DE", "T"
    ],
    fe_video_map: {}
  }),
  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? "dark" : "light";
    },
    getHref() {
      return window.location.origin + "?l=m9jXXZRAO7uVQvvJOZoJh2p7DjX0BW&c=B";
    }
  },
  mounted: function() {
    //get Url-Param p
    const urlParams = new URLSearchParams(window.location.search);
    //set fe-class if exist in classes, else "B"
    const dclass = urlParams.get("c");
    if (dclass != null && dclass.length > 0) {
      //accept only valid drivingclasses
      this.classes.includes(dclass) ?
        this.$store.commit("ADD_DRIVINGCLASS", dclass) :
        this.$store.commit("ADD_DRIVINGCLASS", "B");

    }
    //check hashid on hex
    const hashids = new Hashids();
    /*this.Hashids = urlParams.get("p") != null ? hashids.decode(urlParams.get("p")!.toString()).toString() : "";*/
    const pParam = urlParams.get("p");
    this.Hashids = pParam != null ? hashids.decode(pParam.toString()).toString() : "";
    const lParam = urlParams.get("l");
    this.HashidsHex = lParam != null ? hashids.decodeHex(lParam.toString()).toString() : "";
    if (!this.Hashids && !this.HashidsHex) {
      this.tabitems.shift();
    } else if (this.Hashids != null && this.Hashids.length > 0) {
      this.$store.commit("ADD_PLAYLISTIDS", [false, this.Hashids.toString()]);
      this.$router.push("/exam");
    } else if (this.HashidsHex != null && this.HashidsHex.length > 0) {
      this.$store.commit("ADD_PLAYLISTIDS", [true, this.HashidsHex.toString()]);
      this.$router.push("/exam");
    } else {
      this.tabitems.shift();
    }

    //for darktheme
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.darkmodeswitchvalue = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.darkmodeswitchvalue = false;
      }
    }
    this.getHeightOfAppBarAndSetLogoWidth();
  },
  methods: {
    toggle_dark_mode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    ...mapMutations(["ADD_DRIVINGTASKCATALOG"]),
    addDrivingTaskCatalogToStore: function() {
      this.ADD_DRIVINGTASKCATALOG(this.drivingtasks);
    },
    ...mapMutations(["ADD_OBSERVATIONCATS"]),
    addObservationCatsToStore: function() {
      this.ADD_OBSERVATIONCATS(this.observationcats);
    },
    ...mapMutations(["ADD_DCLASSES"]),
    addDClassesToStore: function() {
      this.ADD_DCLASSES(this.classes);
    },
    ...mapMutations(["ADD_FE_VIDEO_MAP"]),
    addFE_VIDEO_MAPToStore: function() {
      this.fe_video_map = require("@/assets/fe.json");
      this.ADD_FE_VIDEO_MAP(this.fe_video_map);
    },
    goToStart() {
      if (this.$router.currentRoute.name !== "DrivingTaskGrid")
        this.$router.push({ name: "DrivingTaskGrid" });
    },
    getHeightOfAppBarAndSetLogoWidth() {
      let appBar2 = document.getElementsByClassName("appBarHeight")[0];
      const ro2 = new ResizeObserver(() => {
        if (appBar2.clientHeight == 96) this.widthOfLogo = "150px";
        else this.widthOfLogo = "80px";
      });
      ro2.observe(appBar2);
      this.widthOfLogo = "150px";
    }
  },
  created() {
    const html = document.documentElement;
    html.setAttribute("lang", "de");
    this.addDrivingTaskCatalogToStore();
    this.addObservationCatsToStore();
    this.addFE_VIDEO_MAPToStore();
    this.addDClassesToStore();
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route(to, from) {
      if (this.$router.currentRoute.name !== "Exam") {
        this.tab = "/";
      } else {
        this.tab = "/exam";
      }
    }
  }
});


