import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: () =>
            import("../views/Home.vue"),
        children: [
            {
                path: "",//drivingtask-id for routing
                name: "DrivingTaskGrid",
                props: true,
                component: () =>
                    import("../components/panels/DrivingTaskGrid.vue"),
                meta: {
                    breadCrumb() {
                        return [
                            {
                                text: this.$store.state.selecteddrivingclass,
                                header: 'Fahrerlaubnisklasse',

                            }
                        ]
                    }
                }
            },
            {
                path: "/dt:dtid",//drivingtask-id for routing
                name: "SubDrivingTaskGrid",
                props: true,
                component: () =>
                    import(/* webpackChunkName: "subdrivingtasks" */ "../components/panels/SubDrivingTaskGrid.vue"),
                meta: {
                    breadCrumb() {
                        return [
                            {
                                text: this.$store.state.selecteddrivingclass,
                                header: 'Fahrerlaubnisklasse',
                                to: {name: 'DrivingTaskGrid'}
                            },
                            {
                                text: this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid) - 1].name,
                                header: 'Fahraufgabe',
                            }
                        ]
                    }
                }
            },
            {
                path: '/dt:dtid/sdt:sdtid/:sitsubid?',
                name: 'ObservationCatGrid',
                props: true,
                component: () =>
                    import(/* webpackChunkName: "subdrivingtasks" */ "../components/panels/ObservationCatGrid.vue"),
                meta: {
                    breadCrumb() {
                        const ar = [
                            {
                                text: this.$store.state.selecteddrivingclass,
                                header: 'Fahrerlaubnisklasse',
                                to: {name: 'DrivingTaskGrid'}
                            },
                            {
                                text: this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid) - 1].name,
                                header: 'Fahraufgabe',
                                to: {
                                    name: 'SubDrivingTaskGrid',
                                    params: {
                                        dtid: this.$route.params.dtid,
                                        drivingtask: this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid) - 1]
                                    }
                                }
                            },
                            {
                                text: this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid)-1].subdrivingtasks[parseInt(this.$route.params.sdtid)-1] ? this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid)-1].subdrivingtasks[parseInt(this.$route.params.sdtid)-1].name:"",
                                header: 'Teilfahraufgabe'
                            },
                        ]
                        if(this.$route.params.sdtid == 0){
                            delete  ar[1].to;
                            delete ar[2];
                        }
                        if(this.$route.params.sitsubid){
                            ar.push({
                                text: this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid) - 1].subdrivingtasks[parseInt(this.$route.params.sdtid) - 1].
                                    situationsubclasses[parseInt(this.$route.params.sitsubid) - 1].name,
                                header: 'Situation',
                            })
                        }
                        return ar
                    }
                }
            },
        ]
    },
    {
        path: "/exam",
        name: "Exam",
        // route level code-splitting
        // this generates a separate chunk (contact.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "exam" */ "../views/Exam.vue"),
    },
    {
        path: "/relatedlinks",
        name: "ExternalLinks",
        // route level code-splitting
        // this generates a separate chunk (contact.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import("../views/ExternalLinks.vue"),
    },
   /* {
        path: "/contact",
        name: "Contact",
        component: () =>
            import(/!* webpackChunkName: "contact" *!/ "../views/Contact.vue"),
    },*/
    {
        path: "/privacy",
        name: "Privacy",
        component: () =>
            import("../views/Privacy.vue"),
    },
    {
        path: "/imprint",
        name: "Imprint",
        component: () =>
            import("../views/Imprint.vue"),
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: () =>
            import("../views/PageNotFound.vue"),

    },
];

/*function getsdtname(ref){
    if(ref)
    this.$store.state.selecteddrivingtaskcatalog[parseInt(this.$route.params.dtid)-1].subdrivingtasks[parseInt(this.$route.params.sdtid)-1].name,
}*/

const router = new VueRouter({
    /*  mode:"history",*/
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes,
});
const DEFAULT_TITLE = "Anforderungen zur Praktischen Fahrerlaubnisprüfung";
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = DEFAULT_TITLE;
    });
});
export default router;
