import "@mdi/font/css/materialdesignicons.css"
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                appbarbackground: '#ffffff',
            },
            dark:{
                appbarbackground: '#1E1E1E',
            }
        },
    },
})
export default vuetify