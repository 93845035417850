import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        selecteddrivingtaskcatalog: Object,
        selecteddrivingclass: "B",
        observationcats: Object,
        playlistItems: <Array<PlaylistItem>>([]),
        playlistIds: [],
        ishex: Boolean,
        playlistItem: {
            id: Number,
            data: {},
            title: '',
            src: '',
            video_poster: '',
            description: '',
            showPanel: false,
            timeValue: 0
        },
        id: Number,
        fe_video_map: Object,
        dclasses: []

    },
    mutations: {
        initialiseStore(state) {
            // Check if the ID exists
            if (localStorage.getItem('store')) {
                // Replace the state object with the stored item
                const storeLocal = localStorage.getItem('store');
                this.replaceState(
                    Object.assign(state, storeLocal !== null ? JSON.parse(storeLocal) : {})
                );
            }
        },
        ADD_DRIVINGCLASS: (state, drivingclass) => {
            state.selecteddrivingclass = drivingclass;
        },
        ADD_DRIVINGTASKCATALOG: (state, drivingtaskcatalog) => {
            state.selecteddrivingtaskcatalog = drivingtaskcatalog;
        },
        ADD_OBSERVATIONCATS: (state, observationcats) => {
            state.observationcats = observationcats;
        },
        ADD_PLAYLISTIDS: (state, [ishex, playlistIds]) => {
            state.ishex = ishex;
            if (ishex) {
                const playlistIdsToArray = playlistIds.split(/(\d+)/g).filter((a: string) => a);
                const arrayOfIds: any[] = [];
                const helparrayOfIds: any[] = [];
                for (let i = 0; i < playlistIdsToArray.length; i++) {
                    if (Number.isInteger(Number(playlistIdsToArray[i]))) {
                        if (!(helparrayOfIds.includes(playlistIdsToArray[i]+playlistIdsToArray[i+1]))) {
                            arrayOfIds.push(playlistIdsToArray[i]);
                            arrayOfIds.push(playlistIdsToArray[i + 1]);
                        }
                        helparrayOfIds.push(playlistIdsToArray[i]+playlistIdsToArray[i+1])
                    }
                }
                playlistIds = arrayOfIds;
                state.playlistIds = playlistIds;
            } else {
                const playlistIdsToArray = playlistIds.split(',');
                const arrayOfIds = [];
                for (let i = 0; i < playlistIdsToArray.length; i++) {
                    arrayOfIds.push(playlistIdsToArray[i]);
                    arrayOfIds.push("a");
                }
                playlistIds = arrayOfIds;
                state.playlistIds = playlistIds;
            }


        },
        ADD_PLAYLISTITEMS: (state, playlistitems) => {
            state.playlistItems = playlistitems;
        },
        ADD_PLAYLISTITEMTIMEVALUE: (state, [id, currenttime]) => {
            const index = state.playlistItems.findIndex(
                (pel: PlaylistItem) => {
                    return pel.id === id
                });
            if (index !== -1) {
              state.playlistItems[index].timeValue = currenttime;
            }
        },
        ADD_FE_VIDEO_MAP: (state, fe_video_map) => {
            state.fe_video_map = fe_video_map;
        },
        ADD_DCLASSES: (state, dclasses) => {
            state.dclasses = dclasses;
        },
    },
    actions: {},
    modules: {},
});

export interface PlaylistItem {
    id: number;
    /**
     *  "drivinglicence": "B",
     *  */
    drivinglicence: string;
    /**
     * "dt": "1",
     * */
    dt: number;
    /**
     * "sdt": "1",
     * */
    sdt: number;
    /**
     * "subsit": "0",
     * */
    subsit: number;
    /**
     * "obscat": "1",
     * */
    obscat: number;
    /**
     * "perfcat": "2",
     * */
    perfcat: number;
    /**
     * "desc": "ich bin die beschreibung des bewertungskriteriums und ein leichter fehler"
     * */
    desc: string;

    showPanel: boolean,

    timeValue: number,
}

